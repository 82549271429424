import React from "react";
import { makeStyles } from "@material-ui/styles";
import { BottomNavigation, BottomNavigationAction } from "@material-ui/core";
import GitHub from "@material-ui/icons/GitHub";
import LinkedIn from "@material-ui/icons/LinkedIn";
import Twitter from "@material-ui/icons/Twitter";

const useStyles = makeStyles({
  root: {
    "& .MuiBottomNavigationAction-root": {
      minWidth: 0,
      maxWidth: 250,
    },
    "& .MuiSvgIcon-root": {
      fill: "#564F6F",
      "&:hover": {
        fill: "#802BB1",
        fontSize: "1.8rem",
      },
    },
  },
});

const Footer = () => {
  const classes = useStyles();
  return (
    <BottomNavigation width="auto" style={{ background: "#2D283E" }}>
      <a
        href="https://github.com/r1ckza"
        target="_blank"
        rel="noopener noreferrer"
      >
        <BottomNavigationAction
          className={classes.root}
          style={{ padding: 10 }}
          icon={<GitHub />}
        />
      </a>
      <a
        href="https://www.linkedin.com/in/rickyandrew/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <BottomNavigationAction
          className={classes.root}
          style={{ padding: 10 }}
          icon={<LinkedIn />}
        />
      </a>
      <a
        href="https://twitter.com/Rick_LDN"
        target="_blank"
        rel="noopener noreferrer"
      >
        <BottomNavigationAction
          className={classes.root}
          style={{ padding: 10 }}
          icon={<Twitter />}
        />
      </a>
    </BottomNavigation>
  );
};

export default Footer;
