import React from "react";
import {Route} from "react-router-dom"
import CssBaseline from "@material-ui/core/CssBaseline";
import "./App.css";
import Home from "./components/";
import Contacts from "./components/Contact";
import Experience from "./components/Experience";
import Blog from "./components/Blog";

function App() {
  return (
    <>
      <CssBaseline />
      <Route exact path="/" component={Home} />
      <Route path="/experience" component={Experience} />
      <Route path="/blog" component={Blog} />
      <Route path="/contacts" component={Contacts} />
    </>
  );
}

export default App;
